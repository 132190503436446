<template>
  <div class="h-100">
    <!-- Pesanan kosong -->
    <div v-if="!myobooksclose.length" class="empty_data_container">
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#787878"
          stroke-linecap="round"
          stroke-width="2"
          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        />
      </svg>
      <p class="mt-1 mb-0 text-light" style="font-weight: 500">
        Tidak ada riwayat pesanan tersedia
      </p>
    </div>

    <!-- Tabel -->
    <div v-else-if="myobooksclose.length">
      <!-- Tabel desktop -->
      <table class="table my-open-order-table d-none d-lg-block">
        <thead>
          <tr>
            <th>#</th>
            <th>Waktu</th>
            <th>Status</th>
            <th>Beli/Jual</th>
            <th>Harga</th>
            <th>{{ simbol_a }}</th>
            <th>{{ simbol_b }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in myobooksclose" :key="item">
            <td>
              {{ item.id }}
            </td>
            <!-- Time -->
            <td>
              {{ moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss") }}
            </td>

            <td>
              {{ statusShow(item) }}
            </td>

            <!-- Buy/Sell -->
            <td>
              <span v-if="item.market_place == 'Limit Buy'" class="green">
                Beli
              </span>
              <span v-if="item.market_place == 'Limit Sell'" class="red">
                Jual
              </span>
            </td>

            <!-- Price -->
            <td>
              <span v-if="item.market_place == 'Limit Buy'" class="green">
                {{
                  parseFloat(item.market_position).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </span>
              <span v-if="item.market_place == 'Limit Sell'" class="red">
                {{
                  parseFloat(item.market_position).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </span>
            </td>

            <!-- simbol_a -->
            <td>
              <div v-if="item.market_place == 'Limit Buy'">
                {{
                  (
                    parseFloat(item.market_amount_start) /
                    parseFloat(item.market_position)
                  ).numberFormat(currencyAObj.decimal_display)
                }}
              </div>
              <div v-else>
                {{
                  parseFloat(item.market_amount_start).numberFormat(
                    currencyAObj.decimal_display
                  )
                }}
              </div>
            </td>

            <!-- simbol_b -->
            <td>
              <div v-if="item.market_place == 'Limit Buy'">
                {{
                  parseFloat(item.market_amount_start).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </div>
              <div v-else>
                {{
                  (
                    parseFloat(item.market_amount_start) *
                    parseFloat(item.market_position)
                  ).numberFormat(currencyBObj.decimal_display)
                }}
              </div>
            </td>
            <td>
              <button
                v-if="item.matched_count > 0"
                class="btn btn-outline-info btn-sm"
                @click="openModalAndGetDataDetail(item.id)"
              >
                Detail
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Tabel mobile -->
      <table class="table my-open-order-table-vertical d-block d-lg-none">
        <div
          v-for="item in myobooksclose"
          :key="item"
          class="m-3 border border-dark text-light"
        >
          <div class="id_heading px-3 py-2"># {{ item.id }}</div>
          <div class="p-3">
            <!-- Time -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Waktu</span> <span>:</span>
              </div>
              <div class="col">
                {{ moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>

            <!-- Status -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Status</span> <span>:</span>
              </div>
              <div class="col">
                {{ statusShow(item) }}
              </div>
            </div>

            <!-- Buy/Sell -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Beli/Jual</span> <span>:</span>
              </div>
              <div class="col">
                <span v-if="item.market_place == 'Limit Buy'" class="green">
                  Beli
                </span>
                <span v-if="item.market_place == 'Limit Sell'" class="red">
                  Jual
                </span>
              </div>
            </div>

            <!-- Price -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Harga</span> <span>:</span>
              </div>
              <div class="col">
                <span v-if="item.market_place == 'Limit Buy'" class="green">
                  {{
                    parseFloat(item.market_position).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </span>
                <span v-if="item.market_place == 'Limit Sell'" class="red">
                  {{
                    parseFloat(item.market_position).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </span>
              </div>
            </div>

            <!-- simbol_a -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>{{ simbol_a }}</span> <span>:</span>
              </div>
              <div class="col">
                <div v-if="item.market_place == 'Limit Buy'">
                  {{
                    (
                      parseFloat(item.market_amount_start) /
                      parseFloat(item.market_position)
                    ).numberFormat(currencyAObj.decimal_display)
                  }}
                </div>
                <div v-else>
                  {{
                    parseFloat(item.market_amount_start).numberFormat(
                      currencyAObj.decimal_display
                    )
                  }}
                </div>
              </div>
            </div>

            <!-- simbol_b -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>{{ simbol_b }}</span> <span>:</span>
              </div>
              <div class="col">
                <div v-if="item.market_place == 'Limit Buy'">
                  {{
                    parseFloat(item.market_amount_start).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </div>
                <div v-else>
                  {{
                    (
                      parseFloat(item.market_amount_start) *
                      parseFloat(item.market_position)
                    ).numberFormat(currencyBObj.decimal_display)
                  }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              v-if="item.matched_count > 0"
              class="btn btn-outline-info btn-sm w-100"
              @click="openModalAndGetDataDetail(item.id)"
            >
              Detail
            </button>
          </div>
        </div>
      </table>
    </div>

    <div
      class="modal fade text-dark"
      tabindex="-1"
      aria-hidden="true"
      v-bind:id="`myordersclose-detail-modal`"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal_bg">
          <div
            class="modal-header align-items-center px-4 border-bottom border-dark"
          >
            <h5 class="text-light my-auto">Detail</h5>
            <button
              class="btn btn-danger rounded-circle p-1"
              @click="themain.closeModal(`myordersclose-detail-modal`)"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </button>
          </div>
          <div
            class="modal-body d-block"
            style="overflow-y: auto; max-height: 80vh"
          >
            <div style="overflow-x: auto">
              <table class="table table-striped">
                <thead>
                  <tr style="">
                    <!-- 1 -->
                    <th class="special-th" style="font-size: 14px">#</th>
                    <!-- 1a -->
                    <th class="special-th" style="font-size: 14px">At Price</th>
                    <!-- 2 -->
                    <th class="special-th" style="font-size: 14px">
                      {{ simbol_a }} Exchange
                    </th>
                    <!-- 3 -->
                    <th class="special-th" style="font-size: 14px">
                      {{ simbol_a }} Result
                    </th>
                    <!-- 4 -->
                    <!--<th class="special-th" style="font-size: 14px">
                      {{ simbol_a }} Tax
                    </th>-->
                    <!-- 5 -->
                    <!--<th
                      class="special-th"
                      style="width: 11rem; font-size: 14px"
                    >
                      {{ simbol_a }}(Konversi IDR) Tax
                    </th>-->
                    <!-- 6 -->
                    <th class="special-th" style="font-size: 14px">
                      {{ simbol_b }} Exchange
                    </th>
                    <!-- 7 -->
                    <th class="special-th" style="font-size: 14px">
                      {{ simbol_b }} Result
                    </th>
                    <!-- 8 -->
                    <!--<th class="special-th" style="font-size: 14px">
                      {{ simbol_b }} Tax
                    </th>-->
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in closedDetails" :key="item.id">
                    <!-- 1 -->
                    <td class="text-right">{{ item.id }}</td>
                    <!-- 1a -->
                    <td class="text-right">
                      {{ parseFloat(item.mclose_position).numberFormat(8) }}
                    </td>
                    <!-- 2 -->
                    <td class="text-right">
                      {{
                        parseFloat(item.mclose_symbol_a_nofee).numberFormat(8)
                      }}
                    </td>
                    <!-- 3 -->
                    <td class="text-right">
                      {{ parseFloat(item.mclose_symbol_a).numberFormat(8) }}
                    </td>
                    <!-- 4 -->
                    <!--<td class="text-right">
                      {{
                        parseFloat(item.fee_tax_detail?.a_tax || 0)
                      }}
                    </td>-->
                    <!-- 5 -->
                    <!--<td class="text-right" style="width: 11rem">
                      {{
                        parseFloat(item.fee_tax_detail?.a_tax_idr || 0)
                      }}
                    </td>-->
                    <!-- 6 -->
                    <td class="text-right">
                      {{
                        parseFloat(item.mclose_symbol_b_nofee).numberFormat(8)
                      }}
                    </td>
                    <!-- 7 -->
                    <td class="text-right">
                      {{ parseFloat(item.mclose_symbol_b).numberFormat(8) }}
                    </td>
                    <!-- 8 -->
                    <!--<td class="text-right">
                      {{
                        parseFloat(item.fee_tax_detail?.b_tax || 0)
                      }}
                    </td>-->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
  },
  data() {
    return {
      themain,
      moment,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      simbol_a: "",
      simbol_b: "",

      myobooksclose: [],

      closedDetails: [],
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    notif_obooks_result: function (data) {
      var self = this;

      data.data.forEach(async function (el) {
        // request dan tambahkan data yang close
        if (el.uid == self.usid && el.status == "close") {
          await self.getMyOrdersCloseBasedOnWebsocket(el.id);
        }
      });
    },
  },

  async created() {
    // var self = this;
  },

  async mounted() {
    var self = this;

    // simbols
    var simbols = self.marketData.pair.split("_");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];

    // jika ada token, mita ambil myorderbook
    if (self.token != null && self.token != "") {
      await self.getMyOrdersClose();
    }
  },

  methods: {
    async getMyOrdersClose() {
      var self = this;
      var filter_rules = [
        {
          field: "market_pair",
          op: "equal-for-pair",
          value: self.marketData.pair,
        },
      ];
      var myobooksRes = await thestore.getMyOrderBooksClose(
        self.token,
        1,
        20,
        "updated_at",
        "desc",
        filter_rules
      );
      self.myobooksclose = myobooksRes.datas;
    },

    async openModalAndGetDataDetail(id) {
      var self = this;
      var res = await thestore.getCompletedOrderDetail(self.market_pair, id);
      self.closedDetails = res;
      themain.openModal(`myordersclose-detail-modal`);
    },

    async getMyOrdersCloseBasedOnWebsocket(order_id) {
      var self = this;
      var filter_rules = [{ field: "id", op: "equal-num", value: order_id }];
      var myobooksRes = await thestore.getMyOrderBooksClose(
        self.token,
        1,
        1,
        "updated_at",
        "desc",
        filter_rules
      );

      var datas = self.myobooksclose;
      var new_arrs = myobooksRes.datas.concat(datas);
      self.myobooksclose = [];
      self.myobooksclose = new_arrs;
    },

    /**
     * Kita akan tampilkan;
     * jika semua close sempurna maka status close
     * Partial jika dia cancel tetapi ada yang matching
     * Cancel jika tidak ada yang matching
     * Open jika belum ada yang matching juga
     * @param item => market object
     */
    statusShow(item) {
      if (item.market_status == "close") {
        return "close";
      } else {
        if (item.matched_count > 0) {
          return "partial";
        } else {
          return item.market_status;
        }
      }
    },
  },
};
</script>

<style>
.modal_bg {
  background-color: #131722;
}
.my-open-order-table tbody tr,
.my-open-order-table thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.my-open-order-table thead th,
.my-open-order-table tbody td {
  padding: 5px;
  text-align: center;
}

.my-open-order-table-vertical .id_heading {
  font-weight: bold;
  background-color: #1c2030;
}

.empty_data_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .my-open-order-table-vertical {
    margin-bottom: 6rem;
  }

  .empty_data_container {
    padding-bottom: 77px;
  }
}

#myordersclose-detail-modal table {
  width: 60rem;
}

#myordersclose-detail-modal thead tr,
#myordersclose-detail-modal tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
  overflow: auto;
}

#myordersclose-detail-modal td,
th {
  padding: 5px;
  text-align: right;
  word-wrap: break-word;
}

.special-th {
  font-weight: bold;
  color: #708cc7;
  font-size: 1rem;
  text-align: right;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444b60;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a3043;
}
</style>
